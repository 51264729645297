import * as React from 'react'
import Button from 'assets/Button'
import styled from 'styled-components/macro'
import DropDownCard from './DropDownCard'
import {QUERIES} from 'assets/constants'

const Wrapper = styled.div`
  position: relative;
  width: auto;
  display: none;
  @media ${QUERIES.phoneAndUp} {
    display: flex;
  }
`
const StyledButton = styled(Button)`
  margin-left: 5px;
  background: transparent;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  svg {
    margin-right: 5px;
  }
  :hover {
    background: transparent;
    color: var(--secondary);
  }
`
const AltButton = styled(Button)`
  margin-left: 5px;
  background: transparent;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  svg {
    margin-right: 5px;
  }
  :hover {
    background: var(--asc-selectedgreen);
    border: 1px solid var(--primary);
  }
`

type Option = {
  key: number
  text: string
  dataCy?: string
}

type Props = {
  text: string | React.JSX.Element
  direction: 'left' | 'top' | 'right' | 'bottom'
  marginTop?: string
  paddingLR?: string
  options: Option[]
  onChange: (value: number) => void
  dataCy?: string
  altButton?: boolean
  className?: string
  disabled?: boolean
  dataTestId?: string
}

const ButtonDropDown = ({
  text,
  direction,
  options,
  onChange,
  marginTop,
  paddingLR,
  dataCy,
  altButton = false,
  className,
  disabled,
  dataTestId,
}: Props) => {
  const [open, setOpen] = React.useState(false)
  const drop = React.useRef<HTMLDivElement>(null)
  const clickedOutside = () => {
    if (open) {
      setOpen(false)
    }
  }
  // useOnClickOutside(drop, clickedOutside)

  return (
    <Wrapper
      className={className + ' ' + 'button-dropdown'}
      ref={drop}
      data-cy="button-dropdown"
      data-testid={dataTestId}
    >
      {!altButton ? (
        <StyledButton
          data-cy={dataCy}
          onClick={() => {
            setOpen(open => !open)
          }}
          active={open}
          id="dropdown-button"
          disabled={disabled}
        >
          {text}
        </StyledButton>
      ) : (
        <AltButton
          data-cy={dataCy}
          onClick={() => {
            setOpen(open => !open)
          }}
          active={open}
          id="dropdown-button"
          disabled={disabled}
        >
          {text}
        </AltButton>
      )}
      {open && (
        <DropDownCard
          direction={direction}
          options={options}
          setOpen={setOpen}
          onChange={onChange}
          marginTop={marginTop || ''}
          paddingLR={paddingLR}
        />
      )}
    </Wrapper>
  )
}

export default ButtonDropDown
