//
import styled from 'styled-components/macro'
import {Tabs, Tab, TabList} from 'react-tabs'
import React from 'react'
import {currencyFormatter} from './helpers'

export const Button = styled.button`
  color: ${props => (props.fontColor ? props.fontColor : '#ffffff')};
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#1e7e34'};
  padding: 10px 20px;
  display: block;
  margin: 5px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  justify-items: center;
  width: 100%;
  &:hover {
    background: ${props =>
      props.hoverBackgroundColor ? props.hoverBackgroundColor : '#28a745'},
    color: ${props =>
      props.hoverFontColor ? props.hoverFontColor : '#ffffff'};
    box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 0 rgba(34,36,38,.15);
  }
`

export const Message = styled.div`
  text-align: center;
  position: relative;
  min-height: 1em;
  margin: 0;
  background: #f8f8f9;
  padding: 0.8rem 1.5em 0.2rem;
  line-height: 1.1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.2rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;
`

export const Panel = styled.div`
  width: 100%;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 1em 1em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
`

export const NavBody = styled.div`
  margin-top: 120px;
`

export const VerticalTabs = styled(Tabs)`
  display: flex;
  -webkit-tap-highlight-color: transparent;
`

export const VerticalTabList = styled(TabList)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #ddd;
  list-style: none;
  margin-right: 1rem;
  padding-left: 0;
  li.react-tabs__tab--selected:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: 3px solid #1a702f;
    content: '';
    position: absolute;
  }
`

export const VerticalTab = styled(Tab)`
  text-align: center;
  padding: 10px;
  position: relative;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
`

export const HorizontalTabs = styled(Tabs)`
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  width: 100%;
`

export const HorizontalTabList = styled(TabList)`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  /* border-bottom: 1px solid #ddd; */
  list-style: none;
  /* margin-left: 1rem; */
  margin-right: 1rem;
  padding-left: 0;
  margin-bottom: 0;
  li.react-tabs__tab--selected:before {
    top: 0;
    left: 0;
    width: 100%;
    border-top: 3px solid #1a702f;
    border-radius: 2px 2px 0 0;
    content: '';
    position: absolute;
  }
  li.react-tabs__tab--selected:after {
    bottom: -1px;
    left: 0;
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid white;
  }
`

export const HorizontalTab = styled(Tab)`
  text-align: center;
  padding: 10px 7px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
  :hover {
    background-color: #ddd;
    cursor: pointer;
  }
`

export const AdminBanner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 5px;
  background-color: #2b4e72;
  color: white;
  display: flex;
  justify-content: center;
  z-index: 999;
  border: 2px solid;
  cursor: pointer;
`

export const AdminBannerPush = styled.div`
  /* used to push other content below top banner */
  height: 35px;
  width: 100%;
`

export const RegistrationButton = styled.button`
  padding: 10px;
  background-color: #dcdcdc;
  border: 1px solid #555;
  border-radius: 3px;
  color: #111;
  text-decoration: none;
  box-shadow: 1px 1px 1px 0px #ccc;
  height: fit-content;
  :hover {
    background-color: #aaa;
    color: #111;
    box-shadow: inset 0 0 0 1px transparent,
      inset 0 0 0 0 rgba(34, 36, 38, 0.15);
  }
  @media (min-width: 1000px) {
    width: 140px;
    min-width: 140px;
  }
  color: ${props =>
    props.disabled === true ? '#6c757d !important' : '#555555'};
`
