import React from 'react'
import styled from 'styled-components/macro'

type Props = {
  type?: 'full' | 'partial'
  size?: string
}

const Container = styled.div<{type: string, size: string}>`
  position: ${p => (p.type === 'full' ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p =>
    p.type === 'full' ? 'rgba(68, 74, 79, 0.1)' : 'transparent'};
  z-index: ${p => (p.type === 'full' ? '10' : 'auto')};
  .spinner {
    width: ${p => (p.type !== 'full' ? p.size : '40px')};
    height: ${p => (p.type !== 'full' ? p.size : '40px')};
    position: relative;
    margin: 10px auto;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--darkolive);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`

const Spinner = ({type = 'full', size = '40px'}: Props) => {
  return (
    <Container type={type} size={size} data-cy="spinner">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </Container>
  )
}

export default Spinner

