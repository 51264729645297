import * as React from 'react'
import { StyledButton } from './TableButtons'
import styled from 'styled-components/macro'
import TableExportButton from '../TableExportButton'
import GlobalFilter from '../GlobalFilter'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px #ccc;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
`
const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
`

const TitleBadgeGroup = styled(Row)`
  align-items: center;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  width: fit-content;
  gap: 5px;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

interface Props {
  title: string
  totalItems: number
  isUpdating?: boolean
  backUrl?: string
  addOptions?: {
    addAction: () => void
    addButtonText: string
    dropdownItems?: {
      text: string
      dataCy: string
      onClick: () => void
    }[]
  }
  rightHeaderContent?: React.JSX.Element
  showExport: boolean
  exportData: (type: 'xlsx' | 'csv', allRecords: boolean) => void
  testIdName?: string
  oneRowHeader?: boolean
  totalCount?: number
  globalFilter?: string
  setGlobalFilter?: (value: string) => void
}


const ResizableTableHeader: React.FC<Props> = ({
  title,
  totalItems = 0,
  isUpdating,
  addOptions,
  rightHeaderContent,
  showExport,
  exportData,
  testIdName,
  oneRowHeader,
  globalFilter,
  setGlobalFilter,
}) => {
  if (!title) return <></>
  return (
    <Header className="header">
      <HeaderRow>
        <Row>
          <TitleBadgeGroup>
            <Title>{title}</Title>
            {isUpdating && (
              <LoaderContainer>
                Retrieving List...
              </LoaderContainer>
            )}
          </TitleBadgeGroup>
        </Row>
        {oneRowHeader && (
          <Row>
            {' '}
            <GlobalFilter
              totalCount={totalItems}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </Row>
        )}
        <ButtonRow>
          {(showExport || (oneRowHeader && showExport)) && (
            <TableExportButton
              hiddenGlobalFilter={false}
              exportData={exportData}
              testIdName={testIdName}
            />
          )}
          {rightHeaderContent && rightHeaderContent}
          {addOptions?.addAction &&
              <StyledButton
                onClick={addOptions.addAction}
                fontOnPrimary={'white'}
                fontOnSecondary={'black'}
                id={`${testIdName}-add-button`}
              >
                <span>+</span>
                <span>{addOptions.addButtonText}</span>
              </StyledButton>}
        </ButtonRow>
      </HeaderRow>
    </Header>
  )
}
export default ResizableTableHeader
