import React, {PropsWithChildren} from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState,
  Row,
  RowData,
  ColumnDef,
  getExpandedRowModel
} from '@tanstack/react-table'
import {useVirtualizer} from '@tanstack/react-virtual'
import {
  Body,
  SubHeader,
  StyledTableRow,
  ContainerCell,
  Cell,
  Styles,
  RightButtons,
  ScrollableTable,
  CenteredColumn,
  getCommonPinningStyles,
} from './resizableTableStyles'
import {TableHeader, calcColWidth} from './resizableTableHelpers'
import GlobalFilter from '../GlobalFilter'
import TableExportButton from '../TableExportButton'
import {excelExport} from '../excelExport'
import {saveAsCSV} from '../csvExport'
import ResizableTableHeader from './ResizableTableHeader'
import {TableProps} from './ResizableTableTypes'
import LoadingSpinner from 'assets/Spinner'

type State = {
  allRows: unknown[]
  globalFilter: string
  divWidth: number | null
}

type Actions = {type: 'setState'; data: Partial<State>}

const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    default:
      return state
  }
}

const estimateSize = 40

const ResizableTable = <TDataType, TSelectionIdType>({
  title = '',
  data,
  columns,
  status,
  height,
  children,
  initialFilters,
  updateToMatchGlobalFilter,
  showHeader = true,
  showSubHeader = true,
  initSort = [],
  isUpdating = false,
  backUrl,
  rightHeaderContent,
  addOptions,
  filters,
  pinnedColumns,
  showExportInHeader = false,
  testIdName = '',
  oneRowHeader = false,
  totalRowCount,
  rowClick,
  dataCy
}: PropsWithChildren<TableProps<TDataType, TSelectionIdType>>) => {
  const initialState = {
    allRows: [],
    globalFilter: initialFilters?.globalFilter || '',
    divWidth: null,
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const memoizedColumns:
    | ColumnDef<TDataType, TSelectionIdType>[]
    | null = ([] = React.useMemo(() => {
    const newColumns = [...columns]
    if (!tableContainerRef.current?.offsetWidth) return []
    return calcColWidth<TDataType, TSelectionIdType>({
      columns: newColumns,
      // hiddenColumns: [],
      containerWidth: tableContainerRef.current?.offsetWidth - 20 || 0,
    })
  }, [columns, tableContainerRef.current?.offsetWidth]))
  //can't get this to work with a reducer 😡 https://github.com/TanStack/table/discussions/4005

  const [sorting, setSorting] = React.useState<SortingState>(
    memoizedColumns.length ? initSort : [],
  )

  React.useEffect(() => {
    if (initSort?.length && initSort.length > 0 && columns.length) {
      setSorting(initSort)
    }
  }, [initSort, columns])

  const memoizedData = React.useMemo(() => {
    return data || []
  }, [data])

  //hiding columns is no longer supported with show:value
  /*To hide column on table for exporting
      {
      header: '',
      id: 'idAsset',
      accessorKey: 'idAsset',
      cell: '',
      maxSize: 0,
      enableResizing: false,
      meta: {
        disableExport: true,
      },
    },
  */
  const table = useReactTable({
    data: memoizedData || [],
    columns: memoizedColumns || [],
    enableColumnResizing: true,
    enableColumnFilters: Boolean(filters?.showColumnFilters),
    columnResizeMode: 'onChange',
    onGlobalFilterChange: (gf: string) =>
      dispatch({type: 'setState', data: {globalFilter: gf}}),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(), // Enables expandable rows
    //@ts-expect-error - this only applies to expanding tables
    getSubRows: row => row.subRows,
    enablePinning: true,
    enableColumnPinning: true,
    state: {
      sorting: sorting,
      globalFilter: state.globalFilter,
    },
    initialState: {
      globalFilter: initialFilters?.globalFilter || '',
      columnPinning: {
        left: pinnedColumns,
      },
    },
  })

  const {rows: filteredRows} = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: filteredRows.length,
    estimateSize: () => estimateSize,
    // measureElement: measureFn(estimateSize),
  })

  const setFilter = (value: string) => {
    if (!state.allRows.length) {
      const rows = table.getRowModel().rows
      dispatch({
        type: 'setState',
        data: {allRows: rows || [], globalFilter: value},
      })
    } else {
      dispatch({type: 'setState', data: {globalFilter: value}})
    }

    updateToMatchGlobalFilter && updateToMatchGlobalFilter(value)
  }

  const getArrayOfDataForExport = (
    rows: Row<RowData>[],
    columns: ColumnDef<TDataType, TSelectionIdType>[],
  ) => {
    const aoa: Array<Array<RowData>> = []
    rows.forEach(thisRow => {
      const allCells = thisRow.getVisibleCells()
      const row: RowData[] = []
      allCells.forEach((cell, index) => {
        if (!columns[index]?.meta?.disableExport) {
          let value = cell.getValue() || ''
          let noHTML =
            typeof value === 'string' ? value.replace(/<[^>]+>/g, '') : value
          row.push(noHTML)
        }
      })
      aoa.push(row)
    })
    return aoa
  }

  const exportData = (type: 'xlsx' | 'csv', allRecords: boolean) => {
    const rows =
      allRecords && state.allRows.length
        ? state.allRows
        : table.getFilteredRowModel().rows

    const convertedRows = getArrayOfDataForExport(
      rows as Row<RowData>[],
      columns,
    )
    const exportedColumns = columns
      .map(c => {
        if (c.meta?.exportHeader) {
          c.header = c.meta.exportHeader
        }
        return c
      })
      .filter(c => !c.meta?.disableExport)
    if (type === 'xlsx') {
      excelExport<TDataType, TSelectionIdType>({
        columns: exportedColumns,
        data: convertedRows,
        fileName: title,
      })
    } else if (type === 'csv') {
      saveAsCSV(exportedColumns, convertedRows, title)
    }
  }

  return (

      <Styles className="table-container" data-cy={dataCy}>
        {showHeader && (
          <ResizableTableHeader
            title={title}
            totalItems={totalRowCount ? totalRowCount : data?.length}
            isUpdating={isUpdating}
            addOptions={addOptions}
            backUrl={backUrl}
            rightHeaderContent={rightHeaderContent}
            showExport={showExportInHeader}
            exportData={exportData}
            testIdName={testIdName}
            globalFilter={state.globalFilter}
            setGlobalFilter={setFilter}
            oneRowHeader={oneRowHeader}
          />
        )}
        {showSubHeader && !oneRowHeader && (
          <SubHeader>
            <GlobalFilter
              totalCount={filteredRows?.length}
              globalFilter={state.globalFilter}
              setGlobalFilter={setFilter}
            />
            {React.Children.map(
              children,
              child =>
                child &&
                React.cloneElement(<>{child}</>, {tableRows: filteredRows}),
            )}
            <RightButtons>
              {data?.length > 0 && (
                <TableExportButton
                  hiddenGlobalFilter={false}
                  exportData={exportData}
                  testIdName={testIdName}
                />
              )}
            </RightButtons>
          </SubHeader>
        )}
        <ScrollableTable
          ref={tableContainerRef}
          id="tableContainer"
          aria-label="List"
          height={height}
        >
          <table>
            <thead
              style={{
                display: 'grid',
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              {table.getHeaderGroups().map((headerGroup, index) => (
                <React.Fragment key={headerGroup.id + "-" + index}>
                  <tr style={{display: 'flex'}}>
                    {headerGroup.headers.map((header, index) => (
                      <TableHeader<TDataType>
                        header={header}
                        key={header.id + "-" + index}
                        testIdName={testIdName}
                      />
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </thead>
            <Body
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              }}
            >
              {status === 'loading' ? (
                <tr>
                  <ContainerCell colSpan={100}>
                    <LoadingSpinner type={'partial'} />
                  </ContainerCell>
                </tr>
              ) : filteredRows?.length > 0 ? (
                <>
                  {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                    const row = filteredRows[virtualRow.index] as Row<TDataType>
                    const rowClickFn = rowClick
                      ? () => rowClick(row.original)
                      : () => null
                    
                    return (
                      <StyledTableRow
                        data-cy={'tableRow'}
                        key={row.id + "-" + index}
                        hoverRow={'#bbb'}
                        style={{
                          transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                        }}
                        data-index={virtualRow.index}
                        ref={rowVirtualizer.measureElement}
                        onClick={rowClickFn}
                        index={virtualRow.index}
                      >
                        {row.getVisibleCells().map((cell, index) => {
                          return (
                            <ContainerCell
                              width={cell.column.getSize()}
                              index={row.index}
                              key={cell.id + "=" + index}
                              className={
                                cell.column.columnDef.header ===
                                'Direct Distance'
                                  ? 'containerCell directDistance'
                                  : 'containerCell'
                              }
                              style={{...getCommonPinningStyles(cell.column)}}
                            >
                              <Cell
                                disableOverflow={
                                  !cell.column.getCanResize() ||
                                  cell.column.columnDef.header === 'Actions'
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Cell>
                            </ContainerCell>
                          )
                        })}
                      </StyledTableRow>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <ContainerCell colSpan={100} data-cy="noRecords">
                    <CenteredColumn>No records found</CenteredColumn>
                  </ContainerCell>
                </tr>
              )}
            </Body>
          </table>
        </ScrollableTable>
      </Styles>

  )
}

export default ResizableTable
