import * as React from 'react'
import {ColumnDef, Header, flexRender} from '@tanstack/react-table'
import {
  SortedArrow,
  HeaderContainer,
  ResizableHeader,
  getCommonPinningStyles,
} from './resizableTableStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TableHeader = <T,>({
  header,
  testIdName
}: {
  header: Header<T, unknown>
  testIdName?: string
}) => {
  return (
    <>
      <HeaderContainer
        key={header.id}
        data-column-name={header.id} // useEffect depends on that !
        align={header.column.columnDef.meta?.headerAlign || 'left'}
        colSpan={header.colSpan}
        style={{
          width: header.getSize(),
          ...getCommonPinningStyles(header.column),
        }}
        hidden={header.getSize() === 0}
      >
        {header.isPlaceholder ? null : (
          <ResizableHeader
            onClick={header.column.getToggleSortingHandler()}
            canSort={header.column.getCanSort()}
            headerAlign={header.column.columnDef.meta?.headerAlign}
            data-testid={`${testIdName}-header-sort-${header.id.toLowerCase()}`}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {{
              asc: (
                <SortedArrow>
                  <FontAwesomeIcon icon="arrow-up" />
                </SortedArrow>
              ),
              desc: (
                <SortedArrow>
                  <FontAwesomeIcon icon="arrow-down" />
                </SortedArrow>
              ),
            }[header.column.getIsSorted() as string] ?? null}
          </ResizableHeader>
        )}
        {header.column.getCanResize() && (
          <div
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            className={`resizer ${
              header.column.getIsResizing() ? 'isResizing' : ''
            }`}
            data-testid={`${testIdName}-header-resize-${header.id.toLowerCase()}`}
          ></div>
        )}
      </HeaderContainer>
    </>
  )
}

export const DraggableTableHeader = <T,>({
  header,
  testIdName
}: {
  header: Header<T, unknown>
  testIdName?: string
}) => {
  return (
    <>
      <HeaderContainer
        key={header.id}
        data-column-name={header.id} // useEffect depends on that !
        align={header.column.columnDef.meta?.headerAlign || 'left'}
        colSpan={header.colSpan}
        style={{
          width: header.getSize(),
        }}
        hidden={header.getSize() === 0}
      >
        {header.isPlaceholder ? null : (
          <ResizableHeader
            onClick={() => null}
            canSort={false}
            headerAlign={header.column.columnDef.meta?.headerAlign}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </ResizableHeader>
        )}
        {header.column.getCanResize() && (
          <div
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            className={`resizer ${
              header.column.getIsResizing() ? 'isResizing' : ''
            }`}
            data-testid={`${testIdName}-header-resize-${header.id.toLowerCase()}`}
          ></div>
        )}
      </HeaderContainer>
    </>
  )
}

type calcColWidthType<S, T> = {
  columns: ColumnDef<S, T>[]
  // hiddenColumns: string[]
  containerWidth: number
}

export const calcColWidth = <S, T>({
  columns,
  // hiddenColumns,
  containerWidth,
}: calcColWidthType<S, T>) => {
  //calculate width to fill space
  let showingColumns: ColumnDef<S, T>[] = []
  // if (hiddenColumns.length) {
  columns.forEach(col => {
    if (col.maxSize !== 0) {
      showingColumns.push(col)
    }
  })
  // } else {
  //   showingColumns = [...columns]
  // }
  // const availableWidth =
  //   containerWidth - cellPadding - cellPadding * showingColumns.length
  // showingColumns.length * 2 //space for borders and padding
  const widthSpecified = showingColumns.reduce(
    (sum, {size = 0}) => sum + size,
    0,
  )
  const colsWithoutWidth = showingColumns.filter(c => !c.size).length
  let spreadWidth = (containerWidth - widthSpecified) / colsWithoutWidth
  if (spreadWidth < 100) spreadWidth = 100
  const newColumns = columns.map(col => {
    // if (hiddenColumns.findIndex(hc => hc === col.id) === -1) {
    return col.size ? col : {...col, size: spreadWidth}
    // } else {
    //   return {...col, size: 0}
    // }
  })
  return newColumns
}
