import React from 'react'
import {createRoot} from 'react-dom/client'

import {UserProvider} from './Session/context'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-time-picker/assets/index.css'
import 'toastr/build/toastr.min.css'
import 'tippy.js/dist/tippy.css'
import './App.css'

import App from './App'
import * as serviceWorker from './registerServiceWorker'

const container = document.getElementById('root')

const root = createRoot(container)

root.render(
  <UserProvider>
    <App />
  </UserProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
