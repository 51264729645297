export const endpoint = `${process.env.REACT_APP_API_URL ||
  'https://golf-invite-api.herokuapp.com'}/graphql`
//used on LandingPage and HomePage Editors
//not a styled component!
export const landingPageStyles = `
  html {
    font-size: 0.8rem;
  }

  @media (min-width: 992px) {
    html {
      font-size: 0.9rem;
    }
  }

  @media (min-width: 1200px) {
    html {
      font-size: 1rem;
    }
  }
  /* image classes for user-content */
  .img-full-width {
    width: 100%;
    height: auto;
  }

  .img-half-width {
    width: 50%;
    height: auto;
  }

  .img-quarter-width {
    width: 25%;
    height: auto;
  }

  .img-third-width {
    width: 33%;
    height: auto;
  }

  body {
    margin: 3px 10px;
    width: 99vw;
    max-width: 1200px;
    h1 {
      font-size: 2rem;
    }
    padding-top: 62px;
    @media (min-width: 1000px) {
      h1 {
        font-size: 2.5rem;
      }
      width: 90%;
      margin: 0 auto;
      padding-top: 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

`
const BREAKPOINTS = {
  phoneMax: 600,
  tabletMax: 950,
  laptopMax: 1300,
}
export const QUERIES = {
  phoneAndDown: `(max-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  phoneAndUp: `(min-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
}

export const fontSizes =
  '10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 38pt 40pt 42pt'
export const toolbar =
  'insertfile undo redo | styleselect | fontsizeselect | forecolor backcolor | fontselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | hr link image'
export const tableToolbar =
  'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
export const imageClassList = [
  {title: 'Responsive', value: 'img-responsive'},
  {title: 'As Uploaded', value: ''},
  {title: 'Full Width', value: 'img-full-width'},
  {title: 'Half Width', value: 'img-half-width'},
]
export const contentCss =
  'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'
