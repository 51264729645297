import ButtonDropDown from 'assets/DropDownButton/ButtonDropDown'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const getOptions = (hiddenGlobalFilter: boolean, testIdName?: string) => {
  if (hiddenGlobalFilter) {
    return [
      {
        key: 0,
        text: 'Export All XLSX',
        value: 0,
        dataTestId: `${testIdName}-export-xlsx`,
      },
      {
        key: 2,
        text: 'Export All CSV',
        value: 2,
        dataCy: 'export-all-csv',
        dataTestId: `${testIdName}-export-csv`,
      },
    ]
  } else {
    return [
      {
        key: 0,
        text: 'Export All XLSX',
        value: 0,
        dataTestId: `${testIdName}-export-xlsx`,
      },
      {
        key: 1,
        text: 'Export Filtered XLSX',
        value: 1,
        dataTestId: `${testIdName}-export-xlsx-filtered`,
      },
      {
        key: 2,
        text: 'Export All CSV',
        value: 2,
        dataCy: 'export-all-csv',
        dataTestId: `${testIdName}-export-csv`,
      },
      {
        key: 3,
        text: 'Export Filtered CSV',
        value: 3,
        dataTestId: `${testIdName}-export-csv-filtered`,
      },
    ]
  }
}
const dropdownClicked = (
  value: number,
  exportData: (type: 'xlsx' | 'csv' , allRecords: boolean) => void,
) => {
  const type =
    value === 0 || value === 1
      ? 'xlsx'
      : 'csv'
  const allRecords = value === 0 || value === 2 || value === 4
  exportData(type, allRecords)
}

const ExportText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-right: 5px;
  gap: 10px;
  padding: 8px;
  svg {
    margin-top: -3px;
  }
`

type Props = {
  hiddenGlobalFilter: boolean
  exportData: (type: 'xlsx' | 'csv', allRecords: boolean) => void
  testIdName?: string
}

const TableExportButton = ({
  hiddenGlobalFilter = false,
  exportData,
  testIdName,
}: Props) => {
  return (
    <ButtonDropDown
      text={
        <ExportText>
          Export 
                <FontAwesomeIcon icon="download"/>
        </ExportText>
      }
      direction="right"
      options={getOptions(hiddenGlobalFilter, testIdName)}
      onChange={(value: number) => dropdownClicked(value, exportData)}
      dataCy="export-button"
      dataTestId={`${testIdName}-export`}
    />
  )
}
export default TableExportButton
