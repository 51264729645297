import * as React from 'react'
import styled from 'styled-components/macro'
import useEventsReport from './hooks/useEventsReport'
import ResizableTable from 'common/tablesv8/ResizableTable/ResizableTable'
import useUserLookup from '../hooks/useUserLookup'
import {
  currencyColumn,
  formatDate,
  numberColumn,
} from 'common/tablesv8/tableHelpers'

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
`

const EventsReport = () => {
  const {data: user} = useUserLookup()
  const {data: events, status} = useEventsReport(user)

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Planner',
      accessorKey: 'planner',
    },
    {
      header: 'Contact',
      accessorKey: 'contact',
    },
    {
      header: 'Site',
      accessorKey: 'slug',
    },
    {
      header: 'Event Date',
      accessorKey: 'eventDateConverted',
      accessorFn: row =>
        row.eventDateConverted ? new Date(row.eventDateConverted) : null,
      cell: instance => formatDate({value: instance.getValue() || ''}),
    },
    {
      header: 'Course Name',
      accessorKey: 'courseName',
    },
    {
      header: 'Players',
      id: 'playerCount',
      accessorFn: d => Number(d.playerCount),
      cell: numberColumn,
    },
    {
      header: 'Capacity',
      id: 'playerCapacity',
      accessorFn: d => Number(d.playerCapacity),
      cell: numberColumn,
    },
    {
      header: 'Payments',
      id: 'payments',
      accessorFn: d => Number(d.payments),
      cell: currencyColumn,
    },
    {
      header: 'Paypal',
      id: 'paypalPayments',
      accessorFn: d => Number(d.paypalPayments),
      cell: currencyColumn,
    },
  ]

  // if (status === 'loading' || !events) return <Spinner name="double-bounce" />
  // if (status === 'error') return <div>error</div>

  return (
    <Container>
      <ResizableTable
        data={events}
        columns={columns}
        title="Events"
        oneRowHeader={true}
        status={status}
      />
    </Container>
  )
}
export default EventsReport
