import React, {CSSProperties} from 'react'
import styled from 'styled-components/macro'
import {Column} from '@tanstack/react-table'

export type SelectionActionButton = {
  icon?: React.JSX.Element
  text: string
  actionFunction: () => void
}

// eslint-disable-next-line import/no-unused-modules
export const getCommonPinningStyles = <T,>(
  column: Column<T>,
): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right')

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-2px 0 2px -2px gray inset'
      : isFirstRightPinnedColumn
      ? '2px 0 2px -2px gray inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  }
}

export const Styles = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  table {
    border-spacing: 0;
    border: none;

    .resizer {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 8px;
      background: white;
      cursor: col-resize;
      user-select: none;
      touch-action: none;
      border-radius: 2px;
    }

    .resizer.isResizing {
      background: #ccc;
      opacity: 1;
      z-index: 5;
    }

    @media (hover: hover) {
      .resizer {
        opacity: 0;
      }

      *:hover > .resizer {
        opacity: 1;
      }
    }
    thead {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 3;
    }
    /* tr,
    th {
      :last-child {
        td {
          border-bottom: 1px solid var(--table-borders);
        }
      }
    } */
    .subheader {
      margin: 0px !important;
    }
    tr .subheader {
      box-shadow: none !important;
    }
  }

  a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
    &:hover {
      color: var(--tertiary) !important;
      text-decoration: underline;
    }
  }
`

export const Body = styled.tbody`
  position: relative;
`

type ContainerCellType = {
  activeRow?: boolean
  index?: number
  width?: number
}

export const ContainerCell = styled.td<ContainerCellType>`
  display: flex;
  align-items: center;
  background-color: ${({activeRow, index}) =>
    activeRow
      ? 'var(--grey300)'
      : index !== undefined && index % 2 === 0
      ? 'var(--grey050)'
      : 'white'};
  padding: 6px ${({width}) => (width ? 6 : 0)}px;
  border: 1px solid var(--grey050);
`

export const Cell = styled.div<{disableOverflow: boolean}>`
  :first-child {
    /* text-overflow: ellipsis; */
    display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`

export const HeaderContainer = styled.th`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  background-color: var(--primary);
  /* padding: 3px; */
  text-align: left;
  position: relative;
  padding: 0.5rem;
  padding-right: ${({align}: {align: 'left' | 'center' | 'right'}) =>
    align === 'right' ? '10px' : '3px'};
  /* border-bottom: 1px var(--table-borders) solid; */
  background: var(--grey700);
  color: white;
  margin: 0;
  overflow: visible;
  justify-content: center;
  td {
    vertical-align: bottom;
    height: 100%;
  }
  border: 1px solid var(--grey500);
`

export const SubHeader = styled.div`
  background: var(--asc-cultured);
  padding: 5px;
  margin-bottom: 0;
  flex: 1;
  height: 80px;
  max-height: 80px;
  display: flex;
  padding-right: 20px;
  h4 {
    font-size: 1.4rem;
    margin-bottom: 0;
    padding-left: 10px;
  }

  justify-content: space-between;
  align-items: center;
`

export const CenteredColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const SortedArrow = styled.div`
  &&& {
    width: 14px !important;
    height: 14px !important;
    margin-left: 3px;
    margin-right: 10px;
    margin-top: -5px;
    svg {
      width: 14px !important;
      height: 14px !important;
      min-width: 14px !important;
      min-height: 14px !important;
    }
  }
`

export const ScrollableTable = styled.div<{height?: string}>`
  height: ${({height}: {height?: string}) =>
    height ? height : 'calc(100vh - 241px)'};
  overflow: auto;
  position: relative;
  box-sizing: border-box; 
    max-width: 100%;
    width: 100%;
`

export const StyledTableRow = styled.tr<{
  index: number
  hoverRow: string
}>`
  display: flex;
  position: absolute;
  background-color: white;
  /* border: 1px solid lightgray; */
  :hover {
    td {
      background-color: ${p => p.hoverRow};
      color: black !important;
    }
  }
  //visible class allows dropdowns to float other absolutely positioned rows
  :has(.visible) {
    z-index: 99;
  }
  .directDistance {
    background-color: var(--asc-moss) !important;
    font-weight: 700;
    :hover {
      color: black !important;
    }
  }
`

type ResizableHeaderType = {
  canSort: boolean
  headerAlign: string | undefined
}

export const ResizableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: ${({canSort}: ResizableHeaderType) =>
    canSort ? 'pointer' : 'default'};
  justify-content: ${({headerAlign}: ResizableHeaderType) =>
    headerAlign === 'right'
      ? 'flex-end'
      : headerAlign === 'center'
      ? 'center'
      : 'space-between'};
`

export const RightButtons = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`